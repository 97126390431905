<template>
  <v-container>
 <!-- keyboard -->
    <v-row id="keyboard" justify="center">
       <v-col xs="12" sm="6" md="6" lg="4" xl="3">
          <v-row justify="center">
              <Drop @drop="handleDrop($event)">
               <v-col cols="12">

                  <input
                    :style="{background:backGroundColor,width:'100%'}"
                    class="kakko-input-blank"
                    type="text"
                    minlength="1"
                    required="required"
                    :value="`${selectedLetter.eng}/${selectedLetter.hindi} + ${selectedVowel.eng}/${selectedVowel.guj}/${selectedVowel.hindi} = ${answer}`"
                    :readonly="true"
                  />

               </v-col>
                </Drop>

          </v-row>
          <v-row justify="center" class="mt-4 mb-2">

                <span :style="{textAlign:'center', color:$vuetify.theme.themes.light.primary}" class="text-body-2">
                    {{$t('dragDropInstruction')}}
                </span>

          </v-row>

          <v-row justify="center" class="mb-2">
            <v-col class="pa-1"
                    v-for="(l, key) in letters"
                    :key="'bara' + key"
                  >

                    <letter-button
                      :letter="l.guj"
                      :game-over="false"
                      :draggable="true"
                      :key="l+key"
                      @check="check(letter)"
                      v-cloak />
              </v-col>
              <v-col class="pa-1"
                    v-for="(v, key) in vowels"
                    :key="key"
                  >

                    <letter-button
                      :letter="v.symbol"
                      :game-over="false"
                      :draggable="true"
                      :key="v+key"
                      @check="check(letter)"
                      v-cloak />
              </v-col>
          </v-row>
          <v-row>

          </v-row>

      </v-col>

    </v-row>
    <v-row  v-if="practiceCount === 0"  align="center" justify="center">
            <span :style="{textAlign:'center', color:$vuetify.theme.themes.light.primary}" class="text-h6 mr-3">Your skill level is: </span>
            <v-icon v-for="i in skillRating" :key="'star'+i" :color="i.color">
              {{i.icon}}
            </v-icon>
    </v-row>
     <v-row  v-else  align="center" justify="center">
                  <Scorecard :wrongAttempts ="wrongAttempts" :practiceCount = "practiceCount"  :totalAttempts="totalAttempts"/>

    </v-row>
     <v-row v-if="practiceCount === 0" align="center" justify="center" class="mt-2">
           <v-btn raised="true" color="secondary" @click="refresh()">{{$t('Try Again')}}</v-btn>
    </v-row>
   <GoBackButon/>
  </v-container>
</template>

<script>

import LetterButton from './DraggableLetterButton'
import { Drop } from 'vue-drag-drop'
import GoBackButon from './GoBackButton'
import Scorecard from './Scorecard'
import Utils from '../util/Utils'

export default {
  mounted () {
    this.refresh()
  },
  components: {
    LetterButton,
    Drop,
    GoBackButon,
    Scorecard
  },
  computed: {

    letters () {
      return this.$store.state.letters.filter(l => !l.specialLetter)
    },
    vowels () {
      return this.$store.state.vowels
    }
  },
  methods: {
    refresh () {
      this.practiceCount = this.totalAttempts
      this.wrongAttempts = 0
      this.skillRating = []

      this.selectQuestion()
    },
    selectQuestion () {
      this.selectedLetter = this.letters[Math.floor(Math.random() * this.letters.length)]
      this.selectedVowel = this.vowels[Math.floor(Math.random() * this.vowels.length)]
      this.answer = ''
    },
    confettiDrop () {
      Utils.dropConfetti(this.$confetti)
    },
    handleDrop (data) {
      this.answer += data.letter
      if (this.answer.length >= (this.selectedLetter.guj.length + this.selectedVowel.symbol.length)) {
        this.practiceCount--
        if (this.answer === this.selectedLetter.guj + this.selectedVowel.symbol) {
          this.backGroundColor = 'green'
          // this.confettiDrop()
        } else {
          this.wrongAttempts++
          this.backGroundColor = 'red'
        }
        setTimeout(() => {
          this.backGroundColor = 'white'
          this.selectQuestion()
        }, 1000)

        if (this.practiceCount === 0) {
          let score = this.totalAttempts - this.wrongAttempts
          this.skillRating = []
          while (score >= this.totalAttempts / 5) {
            this.skillRating.push({ icon: 'mdi-star', color: this.$vuetify.theme.themes.light.secondary })
            score = score - this.totalAttempts / 5
          }
          if (score >= this.totalAttempts / 10) {
            this.skillRating.push({ icon: 'mdi-star-half-full', color: this.$vuetify.theme.themes.light.secondary })
          }
          while (this.skillRating.length < 5) {
            this.skillRating.push({ icon: 'mdi-star-outline', color: '' })
          }
          if (this.skillRating.filter(s => s.icon === 'mdi-star-outline').length <= 1) {
            this.confettiDrop()
          }
        }
      }
    }
  },
  data: () => ({
    totalAttempts: 50,
    skillRating: [],
    selectedLetter: { guj: 'ક', eng: 'ka' },
    selectedVowel: '',
    answer: '',
    tryagain: false,
    ratingTurnedOn: false,
    practiceCount: 0,
    wrongAttempts: 0,
    practiceAgain: false,
    backGroundColor: 'white'
  })
}
</script>
<style>

  .letter {
    padding: 1px;
    margin: 5px;
    text-shadow: 0 1px 1px #eee;
    font: normal 40px "Lucida Sans Unicode", "Lucida Grande", "Arial Unicode MS", sans-serif;

}

/* D. keyboard */
#keyboard {
  margin-left: 2px;
  margin-right: 2px;
}
.keyboardWidth {
  max-width: 400px;
}
.keyboard-row-letter {
  height: 40px;
  width: 30px;
  font-size: x-large;
}

.blink{
  animation: blink 1s linear infinite;
  color: red
}
@keyframes blink{
0%{opacity: 0;}
50%{opacity: .5;}
100%{opacity: 1;}
}
</style>
